@tailwind base;
@tailwind components;
@tailwind utilities;

div[data-slot="content"] {
  background-color: #18181b;
  color: #fff;
}

audio::-webkit-media-controls-panel {
  background-color: #000;
}

audio::part(panel) {
  background-color: transparent; /* Make panel transparent */
}

* {
  scrollbar-width: auto;
  scrollbar-color: #3b3b3b #000000;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #000000;
}

*::-webkit-scrollbar-thumb {
  background-color: #212121;
  border-radius: 10px;
  border: 0px solid #000000;
}
